<template>
    <div class="gst-event-tabs-navigation d-flex u-width-100">
        <BaseButton
            v-for="tab in tabs"
            :key="tab.id"
            class="gst-event-tabs-navigation__btn d-flex flex-grow-1"
            :class="{
                'active': activeTab === tab.id
            }"
            text
            :ripple="false"
            :data-test-id="$testId(`button.${tab.title.toLowerCase()}`)"
            @click="onClickButtonDo( tab.id )">
            {{ tab.title }}
            <ChipNew v-if="tab.badgeNew" class="ml-1" />
        </BaseButton>
    </div>
</template>

<script>
    import BaseButton from '@core/shared/components/buttons/BaseButton';
    import ChipNew from '@tenant/app/components/chips/ChipNew';

    export default {
        name: 'EventTabsNavigation',
        components: {
            BaseButton,
            ChipNew
        },
        testIdOptions: {
            keyPrefix: 'event._components.newEventTabsNavigation'
        },
        props: {
            tabs: {
                type: Array,
                required: true
            },
            activeTab: {
                type: String,
                default: null
            }
        },
        methods: {
            onClickButtonDo( id ) {
                this.$emit( 'change', { tab: id } );
            }
        }
    };
</script>

<style lang="scss" scoped>
@import "@scssVariables";
@import "@scssMixins";

.gst-event-tabs-navigation {
    position: absolute;
    top: -36px;
    right: 0;

    .gst-event-tabs-navigation__btn {
        line-height: line-height( 'xxxl' );
        color: theme-color( 'white' );
        font-size: font-size( 'l' );
        font-weight: font-weight( 'large' );
        border-radius: border-radius( 'none' );
        border-bottom: 4px solid theme-color( 'transparent' );
    }

    .gst-event-tabs-navigation__btn.active {
        border-bottom: 4px solid theme-color( 'primary-lighten-2' );
    }
}

@include mobile-only {
    .gst-event-tabs-navigation {
        position: relative;
        top: 0;
        max-width: unset;

        .gst-event-tabs-navigation__btn {
            height: theme-spacing( 10 );
            color: theme-color( 'tertiary' );
            font-size: font-size( 's' );
            border-bottom: 2px solid theme-color( 'transparent' );
        }

        .gst-event-tabs-navigation__btn.active {
            color: theme-color( 'primary' );
            border-bottom: 2px solid theme-color( 'primary' );
        }
    }
}
</style>