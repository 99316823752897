<template>
    <img v-if="pixelUrl" height="1" width="1" style="display: none; border-style: none;" alt="" :src="pixelUrl" />
</template>

<script>
    export default {
        name: 'TradeDeskAnalyticsTracker',
        props: {
            queryParams: {
                type: Object,
                default: () => ( {} )
            }
        },
        computed: {
            pixelUrl() {
                if( this.queryParams ) {
                    const baseUrl = 'https://insight.adsrvr.org/track/pxl/?adv=o3m2gdr&ct=0:k5qd83v&fmt=3';
                    const queryString = Object.entries( this.queryParams )
                        .map( ( [ key, value ] ) => `${encodeURIComponent( key )}=${encodeURIComponent( value )}` )
                        .join( '&' );

                    return `${baseUrl}&${queryString}`;

                } else {
                    return null;
                }
            }
        }
    };
</script>