export default {
    computed: {
        googleAnalyticsData() {
            if( this.event.id ) {
                const data = {
                    'u6': this.event.legacyEventId || '',
                    'u7': this.event.name || '',
                    'u8': this.event.partnerVenueId || '',
                    'u9': this.event.venueName || ''
                };

                return data;
            } else {
                return null;
            }
        },
        facebookAnalyticsData() {
            if( this.event.id ) {
                const data = {
                    hostname: window.location.hostname || '',
                    platform: 'gs-travel',
                    venue_name: this.event.venueName || '',
                    venue_id: this.event.partnerVenueId || '',
                    event_id: this.event.legacyEventId  || '',
                    event_name: this.event.name || '',
                    content_ids: this.event.id || '',
                };

                return data;
            } else {
                return null;
            }
        },
        tradeDeskAnalyticsData() {
            if( this.event.id ) {
                const data = {
                    td5: this.event.partnerVenueId || '',
                    td6: this.event.venueName || '',
                    td7: this.event.legacyEventId  || '',
                    td8: this.event.name || ''
                };

                return data;
            } else {
                return null;
            }
        }
    }
};