
<template>
    <div ref="FacebookAnalytics"></div>
</template>
<!-- eslint-disable semi -->
<!-- eslint-disable no-undef -->
<!-- eslint-disable space-in-parens -->
<!-- eslint-disable vue/script-indent -->
<!-- eslint-disable comma-spacing -->
<script>
    export default {
        name: 'FacebookAnalyticsTracker',
        props: {
            customArguments: {
                type: Object,
                default: () => ( {} )
            },
        },
        mounted() {
            if( this.customArguments ) {

                // Load Facebook Pixel script
                if ( !window.fbq) {
                    !function(f,b,e,v,n,t,s)
                    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                    n.queue=[];t=b.createElement(e);t.async=!0;
                    t.src=v;s=b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t,s)}(window, document,'script',
                    'https://connect.facebook.net/en_US/fbevents.js');
                }

                // Initialize Facebook Pixel and pass custom arguments
                const initializeFacebookPixel = () => {
                    fbq('init', '385611125208163');
                    fbq('track', 'PageView');
                    fbq('track', 'ViewContent', {
                        ...this.customArguments
                    }); 
                }

                if( typeof fbq === 'function') {
                    initializeFacebookPixel();
                } else {
                    window.addEventListener('fbq.loaded', initializeFacebookPixel);
                }
                
            }
        }
    };
</script>
