import { format, isBefore, addDays, getStartOfDay } from '@tenant/app/utils/dateUtils';
import { validateTokenStatus } from './tokenUtils';

export function minCheckInDate( ignoreThreshold = false, tokenStatus ) {
    const dateNow = new Date( );
    const startOfDayNow = getStartOfDay( dateNow );

    // if the user used a valid UID (closed user group) or ignoreThreshold is true allow same day check-in
    if ( validateTokenStatus( tokenStatus ) || ignoreThreshold ) {
        return startOfDayNow;
    }

    // else return the day after tomorrow if current date is past 05:00 UTC
    const dateThreshold = new Date( format( dateNow, 'yyyy-MM-dd' ) + 'T05:00:00Z' );
    if ( isBefore( dateNow, dateThreshold ) ) {
        return addDays( startOfDayNow, 1 );
    }
    return addDays( startOfDayNow, 2 );
}