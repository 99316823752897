<template>
    <div ref="GoogleAnalytics"></div>
</template>

<script>
    export default {
        name: 'GoogleAnalyticsTracker',
        props: {
            customArguments: {
                type: Object,
                default: () => ( {} )
            }
        },
        methods: {
            sendArgumentsToGtag() {
                if ( typeof window !== 'undefined' && window.gtag ) {
                    const customArguments = {
                        'allow_custom_scripts': true,
                        ...this.customArguments,
                        'send_to': 'DC-14178161/rtghg0/tmte_0+standard',
                    };
                    window.gtag( 'js', new Date() );
                    window.gtag( 'event', 'conversion', customArguments );
                }
            }
        },
        async created() {
            await this.sendArgumentsToGtag();
        }
    };
</script>
