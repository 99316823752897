<template>
    <div class="gst-event">
        <TheHeader v-if="$vuetify.breakpoint.mdAndUp" hide-navigation />
        <DataLoading v-if="loading" class="gst-event__loading" />
        <div v-if="!loading" class="d-flex flex-column u-height-100" :style="contentStyle">
            <div class="d-flex flex-column u-height-100">
                <EventBanner :item="event" @click-back="onBannerClickBackDo" />
                <div v-if="loadingPackageOfferHotels">
                    <DataLoading class="my-5 py-5" />
                </div>
                <template v-else-if="!isEventNotAvailable">
                    <div class="col col-md-4 col-lg-3 pa-0 u-position-relative ml-auto flex-grow-0" :style="{ 'flex': 1 }">
                        <EventTabsNavigation :active-tab="activeTab" :tabs="tabs" @change="changeTab" />
                    </div>
                    <EventContentPackageOffers
                        v-if="isPackageFlow"
                        :active-tab="activeTab"
                        :event="event"
                        :venue-details="venueDetails"
                        :hotels="packageOfferHotels"
                        @changeTab="changeTab"
                        @checkout="preCheckout" />
                    <EventContent
                        v-else
                        :active-tab="activeTab"
                        :event="event"
                        :hotels="packageOfferHotels"
                        :bus-event-parent="busEvent"
                        @changeTab="changeTab"
                        @checkout="preCheckout"
                        @selected-hotel-reservation="onSelectedHotelReservationDo" />
                </template>
                <template v-else>
                    <EventNotAvailable
                        :event="event"
                        :is-experience-offers-not-available="isExperiencesFlow && isEventPackageOffersNotAvailable" />
                </template>
            </div>
        </div>
        <router-view />
        <div v-if="googleAnalyticsData">
            <GoogleAnalyticsTracker :custom-arguments="googleAnalyticsData" />
        </div>
        <div v-if="facebookAnalyticsData">
            <FacebookAnalyticsTracker :custom-arguments="facebookAnalyticsData" />
        </div>
        <div v-if="tradeDeskAnalyticsData">
            <TradeDeskAnalyticsTracker :query-params="tradeDeskAnalyticsData" />
        </div>
    </div>
</template>

<script>
    import { mapActions, mapState, mapGetters } from 'vuex';
    import Vue from 'vue';
    import apiConstants from '@core/utils/constants/api';
    import { getFromApiResponse as notificationUtilsGetFromApiResponse } from '@core/utils/notificationUtils';
    import {
        hasTicketAvailable as eventUtilsHasTicketAvailable
    } from '@core/utils/eventUtils';
    import EntityNotFoundRedirect404 from '@core/mixins/EntityNotFoundRedirect404';
    import DataLoading from '@core/shared/components/loading/DataLoading.vue';
    import ApiCancelService from '@core/services/ApiCancelService';
    import ViewDocumentTitleMixin from '@tenant/app/mixins/ViewDocumentTitleMixin';
    import tmEventConstants from '@tenant/app/utils/constants/event';
    import { urlOfferTypeToFlow } from '@tenant/app/utils/eventUtils';
    import EventFlowMixin from '@tenant/app/mixins/EventFlowMixin';
    import TheHeader from '@tenant/app/layouts/components/TheHeader';
    import GoogleAnalyticsTracker from '@tenant/app/components/analytics/GoogleAnalyticsTracker.vue';
    import AnalyticsDataTrackerMixin from '@tenant/app/mixins/AnalyticsDataTracker.js';
    import FacebookAnalyticsTracker from '@tenant/app/components/analytics/FacebookAnalyticsTracker.vue';
    import TradeDeskAnalyticsTracker from '@tenant/app/components/analytics/TradeDeskAnalyticsTracker.vue';
    import EventTabsNavigation from './TheEvent/_components/EventTabsNavigation';
    import EventBanner from './TheEvent/_components/EventBanner.vue';
    import HealthCheckConfirmationModal from './TheEventPackageOffer/HealthCheckConfirmationModal';

    const STORE_NAME = 'events';

    const EVENT_TABS = {
        PACKAGES: 'packages',
        TICKETS: 'tickets',
        HOTELS: 'hotels',
    };

    export default {
        name: 'TheEvent',
        components: {
            EventBanner,
            DataLoading,
            TheHeader,
            EventTabsNavigation,
            GoogleAnalyticsTracker,
            FacebookAnalyticsTracker,
            TradeDeskAnalyticsTracker,
            EventContentPackageOffers: ( ) => import( './TheEvent/EventContentPackageOffers.vue' ),
            EventContent: ( ) => import( './TheEvent/EventContent.vue' ),
            EventNotAvailable: ( ) => import( './TheEvent/EventNotAvailable' ),
        },
        i18nOptions: {
            namespaces: 'main',
            keyPrefix: 'views.eventV2.theEvent'
        },
        testIdOptions: {
            keyPrefix: 'newEvent'
        },
        mixins: [
            EntityNotFoundRedirect404,
            EventFlowMixin,
            ViewDocumentTitleMixin,
            AnalyticsDataTrackerMixin,
        ],
        data( ) {
            return {
                loading: true,
                event: { },
                hotels: [ ],
                previousRoute: { },
                /**
                 * TODO ionut
                 * This is PackageFlow || ExperienceFlow
                 * We need to use the event flow from the EventStore
                 */
                isPackageFlow: true,
                activeTab: EVENT_TABS.PACKAGES,
                venueDetails: {
                    mapUrl: '',
                    mapWithlabelsUrl: '',
                    defaultMapUrl: ''
                },
                loadingPackageOfferHotels: false,
                packageOfferHotels: [ ],
                busEvent: new Vue( ),
                selectedHotelReservation: null,
                offerType: null,
            };
        },
        computed: {
            ...mapState( {
                selectedHotel: state => state.packagesOffersV2.hotels.selectedHotel,
                selectedTicket: state =>  state.packagesOffersV2.hotels.selectedTicket
            } ),
            ...mapGetters( {
                hasPackageByEventId             : `packageOffers/hasByEventId`,
                getPackageAvailable             : `packagesOffersV2/getAvailable`,
                loadingSections                 : `packageOffers/getLoading`,
            } ),
            hasHealthCheck( ) {
                return !!this.event.healthCheck;
            },
            routeId( ) {
                return this.$route.params.id;
            },
            routeView( ) {
                return this.$route.params.view;
            },
            routeOfferType( ) {
                return this.$route.params.offerType;
            },
            itemId( ) {
                return this.event.id;
            },
            isUsingLegacyRoute() {
                return this.$route.name === 'tm-event' || this.$route.name === 'tmEventWithOffer';
            },
            isEventTicketsNotAvailable( ) {
                if( this.isExperiencesFlow && this.isEventPackageOffersNotAvailable ) {
                    return true;
                }
                if( this.isPackageFlow ) {
                    return false;
                }
                return !eventUtilsHasTicketAvailable( this.event );
            },
            isEventPackageOffersNotAvailable() {
                return !this.packageOfferHotels.length;
            },
            isEventNotAvailable( ) {
                return  this.isEventTicketsNotAvailable;
            },
            tabs() {
                if ( this.isPackageFlow ) {
                    return [
                        {
                            id: EVENT_TABS.PACKAGES,
                            title: this.$t( '_components.eventTabsNavigation.packages' ),
                        },
                        {
                            id: EVENT_TABS.TICKETS,
                            title: this.$t( '_components.eventTabsNavigation.seats' ),
                        },
                    ];
                }

                return [
                    {
                        id: EVENT_TABS.TICKETS,
                        title: this.$t( '_components.eventTabsNavigation.tickets' ),
                    },
                    {
                        id: EVENT_TABS.HOTELS,
                        title: this.$t( '_components.eventTabsNavigation.hotels' ),
                        badgeNew: true
                    }
                ];
            },
            eventFlowFromRouteOfferType( ) {
                return urlOfferTypeToFlow ( this.routeOfferType );
            },
            isEventPackageOfferPasscodeProtected( ) {
                if ( !this.isEventPackageOffersNotAvailable ) {
                    return this.packageOfferHotels[0]?.rooms[0]?.rates[0]?.packageOffer?.passcodeProtected || false;
                }
                return false;
            },
            contentStyle( ) {
                return { paddingTop: this.$vuetify.breakpoint.mdAndUp ? '50px' : 0 };
            },
            routeTab( ) {
                const tab = ( this.$route.query.tab || '' ).toLowerCase();
                if ( tab && Object.values( EVENT_TABS ).includes( tab ) ) {
                    return tab;
                }
                return null;
            }
        },
        watch: {
            '$route.params.id': function( ) {
                this.loadData( );
            },
            '$route.params.offerType': function( ) {
                this.loadData( );
            },
        },
        methods: {
            ...mapActions( {
                loadOne                         : `${STORE_NAME}/one`,
                loadOneByLegacy                 : `${STORE_NAME}/oneByLegacy`,
                setEventFlow                    : `${STORE_NAME}/setEventFlow`,
                clearEventFlow                  : `${STORE_NAME}/clearEventFlow`,
                getPackageOfferDetailsByEventId : `packagesOffersV2/oneByEventId`,
                getPackageOffersHotelsByEventId : `packagesOffersV2/hotels/getByEventId`,
                notificationError               : 'notification/error',
                notificationWarning             : 'notification/warning',
                setHamburgerMenuVisible         : 'appState/setHamburgerMenuVisible',
                setHotelsEventId: 'addHotelReservationState/hotels/setEventId',
                updateLanguagesForEvent: 'languages/updateLanguagesForEvent'
            } ),
            async loadData( ) {
                let event, packageDetails;
                const { event: eventData, packageDetails: packageDetailsData } = this.$route.params;

                if ( eventData ) {
                    this.event = eventData;
                    event = eventData;
                    packageDetails = packageDetailsData;
                } else {
                    this.loading = true;
                    event = !this._destroyed && await this.loadEvent( );
                    if ( this.routeOfferType ) {
                        this.offerType = tmEventConstants.OFFER_TYPES_TO_PACKAGES[this.routeOfferType];
                    }
                    if ( event && !this._destroyed ) {
                        packageDetails =  await this.getPackageOfferDetailsByEventId( {
                            eventId: this.itemId,
                            offerType: this.offerType
                        } );
                    }
                }

                this.loading = false;

                if ( event ) {
                    this.updateLanguagesForEvent( event.legacyEventId );
                    this.setEventFlowBaseOnUrl( );
                    this.isPackageFlow = this.getPackageAvailable( this.itemId );
                    const { eventDetails } = packageDetails.data;

                    if ( !this.venueDetails.mapUrl ) {
                        this.venueDetails.mapUrl = eventDetails.venueMapWithLabelsUrl;
                        this.venueDetails.mapWithlabelsUrl = eventDetails.venueMapWithLabelsUrl;
                        this.venueDetails.defaultMapUrl = eventDetails.venueMapWithLabelsUrl;
                    }
                    if ( this.isPackageFlow ) {
                        await this.loadPackageOffers( );
                    }
                    await this.initAfterLoad( );
                }
            },
            async loadEvent( ) {
                const getEventData = ( ) => {
                    if ( this.isUsingLegacyRoute ) {
                        return this.loadOneByLegacy( { id: this.routeId } );
                    }
                    return this.loadOne( this.routeId );
                };
                const responseEvent = await getEventData( );

                if ( !responseEvent ) {
                    this._goToNotFoundPage( );
                    return;
                }

                this.clearEventFlow( );
                this.event = responseEvent;

                return this.event;
            },
            async loadPackageOffers( ) {
                if ( this.isPackageFlow ) {
                    this.loadingPackageOfferHotels = true;
                    const responseData = await this.getPackageOffersHotelsByEventId( { eventId: this.itemId, offerType: this.offerType } );
                    this.packageOfferHotels = responseData.hotels || [];
                    this.loadingPackageOfferHotels = false;
                    return this.packageOfferHotels;
                }
            },
            async initAfterLoad( ) {
                if ( this.isExperiencesFlow ) {
                    const urlOfferId = +this.$route.query.selectOfferId;
                    let selectedPackageOffer;

                    if ( urlOfferId ) {
                        selectedPackageOffer = this.packageOfferHotels.find( item => item.selectedRoom.selectedRate.packageOffer.id === urlOfferId );
                    }

                    this.updateDocumentTitle( this.$t(
                        'main:viewsTitle.theEvent.experiences',
                        { package: selectedPackageOffer ? selectedPackageOffer.name : this.event.name }
                    ) );
                } else {
                    this.updateDocumentTitle( this.$t( 'main:viewsTitle.theEvent.packages', { package: this.event.name } ) );
                }
                if ( this.isExperiencesFlow ) {
                    return;
                }
                if ( this.isEventPackageOffersNotAvailable ) {
                    this.setEventFlow( { flow: tmEventConstants.FLOWS.DYNAMIC } );
                    this.setHotelsEventId( this.event.id );
                    this.activeTab = this.routeTab || EVENT_TABS.TICKETS;
                    this.isPackageFlow = false;

                    if ( this.hasHealthCheck && !this.isEventNotAvailable ) {
                        this.openHealthCheckConfirmationModal( );
                    }
                }
            },
            setEventFlowBaseOnUrl( ) {
                if ( this.eventFlowFromRouteOfferType === tmEventConstants.FLOWS.EXPERIENCES ) {
                    this.setEventFlow( { flow: tmEventConstants.FLOWS.EXPERIENCES } );
                    this.setHamburgerMenuVisible( false );
                }
            },
            onBannerClickBackDo( ) {
                this.$router.go( -1 );
            },
            openEventPackageOffersNotAvailableModal( ) {
                this.$modal.show(
                    ( ) => import( './TheEvent/EventPackageOffers/EventPackageOfferNotAvailableModal' ),
                    { },
                    {
                        'max-width': 858
                    },
                    {
                        beforeClose: async ( ) => {
                            this.activeTab = this.isPackageFlow ? EVENT_TABS.PACKAGES : EVENT_TABS.TICKETS;
                            const packageOffersHotels = await this.loadPackageOffers( );
                            if( !packageOffersHotels.length ) {
                                this.initAfterLoad( );
                            }
                        }
                    }
                );
            },
            openPasscodeModal ( payload ) {
                this.$modal.show(
                    (  ) => import( '@tenants/ticketmaster/app/components/modals/PasscodeModal' ),
                    {
                        onUnlockFn: ( passcode ) => {
                            payload.offer.passcode = passcode;
                            this.checkout( payload );
                        }
                    },
                    {
                        'width': 'unset',
                        persistent: true,
                    },
                );

            },
            openHotelRoomRateChangedModal( response ) {
                const { detail } = response;
                const currency = this.selectedHotelReservation ? this.selectedHotelReservation.item.selectedRoom.selectedRate.currency : this.event.currency;
                const hotelRoomOldTotalPrice = this.$options.filters.currencyFilter( detail.hotelRoom.oldTotalPrice, currency );
                const hotelRoomNewTotalPrice = this.$options.filters.currencyFilter( detail.hotelRoom.newTotalPrice, currency );
                const giftCardAmountOld = detail.giftCard.oldAmount;
                const giftCardAmountNew = detail.giftCard.newAmount;
                const newTotalPrice = this.$options.filters.currencyFilter( detail.total.newTotalPrice, currency );
                const i18nPrefix = 'main:views.eventV2.theEvent._components.eventHotelRoomRateChangedModal';
                const title = this.$t( `${i18nPrefix}.title` );
                const showContentWithGiftCard = !!giftCardAmountOld || !!giftCardAmountNew;
                let content;
                if ( showContentWithGiftCard ) {
                    content = this.$t(
                        `${i18nPrefix}.contentWithGiftCard`,
                        {
                            oldPrice: hotelRoomOldTotalPrice,
                            newPrice: hotelRoomNewTotalPrice,
                            newTotalPrice: newTotalPrice,
                            newGiftCardValue: this.$options.filters.currencyFilter( giftCardAmountNew, currency )
                        } );
                } else {
                    content = this.$t(
                        `${i18nPrefix}.content`,
                        {
                            oldPrice: hotelRoomOldTotalPrice,
                            newPrice: hotelRoomNewTotalPrice,
                            newTotalPrice: newTotalPrice
                        } );
                }
                const cancelButton = this.$t( `${i18nPrefix}.buttons.cancel` );
                const confirmButton = this.$t( `${i18nPrefix}.buttons.confirm` );

                this.$modal.show(
                    ( ) => import( '@tenants/ticketmaster/app/components/modals/ConfirmationModal' ),
                    {
                        title,
                        content,
                        cancelButton,
                        confirmButton,
                        dataTestId: 'priceChangeConfirmation',
                        onCancelFn: ( ) => this.busEvent.$emit( 'event-hotel-room-rate-changed-back' ),
                        onConfirmFn: ( ) => this.busEvent.$emit( 'event-hotel-room-rate-changed-next', detail.hotelRoom.newTotalPrice, detail.hotelRoom.oldTotalPrice, detail.giftCard.newAmount, detail.hotelRoom.newRateId )
                    },
                    {
                        'max-width': 858,
                        persistent: true,
                    }
                );
            },
            openHealthCheckConfirmationModal( ) {
                const { healthCheck } = this.event;
                this.$modal.show(
                    HealthCheckConfirmationModal,
                    {
                        healthCheck
                    },
                    {
                        'no-click-animation': true,
                        scrollable: true,
                        fullscreen: false,
                        persistent: true,
                        'hide-overlay': false,
                        'max-width': 858,
                        'content-class': 'gst-v-dialog',
                    }
                );
            },
            preCheckout( payload ) {
                // packagePasscodeProtected comes from BE on the first hotel.room.rate.packageOffer
                if ( this.isEventPackageOfferPasscodeProtected ) {
                    this.openPasscodeModal( payload );
                } else {
                    this.checkout( payload );
                }

            },
            async checkout( payload ) {
                const callbackAddItemToCartErrorFn = ( response ) => {
                    const { code } = response;
                    const i18Prefix = '_common:messageServerErrorCodesFromContext.reserve';
                    switch ( code ) {
                        case apiConstants.ERROR_CODES.HOTEL_PROVIDER.PRODUCT_ERROR:
                            this.notificationWarning( {
                                title: this.$t( `${i18Prefix}.${code}.title` ),
                                content: this.$t( `${i18Prefix}.${code}.detail` ),
                                autoDismiss: false
                            } );
                            break;
                        case apiConstants.ERROR_CODES.HOTEL_PROVIDER.INVALID_REQUEST:
                        case apiConstants.ERROR_CODES.HOTEL_PROVIDER.INVALID_DATA:
                        case apiConstants.ERROR_CODES.HOTEL_PROVIDER.SYSTEM_ERROR:
                            this.notificationError( {
                                title: this.$t( `${i18Prefix}.hotelProviderDefault.title` ),
                                content: this.$t( `${i18Prefix}.hotelProviderDefault.detail` )
                            } );
                            break;
                        case apiConstants.ERROR_CODES.PACKAGE_OFFERS_NOT_AVAILABLE:
                            this.openEventPackageOffersNotAvailableModal( );
                            break;
                        case apiConstants.ERROR_CODES.EVENT_PROVIDER.TICKET_ERROR:

                            if ( this.isPackageFlow ) {
                                this.openEventPackageOffersNotAvailableModal( );
                            } else {
                                this.notificationError( notificationUtilsGetFromApiResponse( response ) );
                            }
                            break;
                        case apiConstants.ERROR_CODES.HOTEL_PROVIDER.ROOM_RATE_CHANGED:
                            this.openHotelRoomRateChangedModal( response );
                            break;
                        default:
                            this.notificationError( notificationUtilsGetFromApiResponse( response ) );
                            break;
                    }
                };

                this.$router.push( { name: `${this.$route.name}.reservation`, params: { payload, callbackAddItemToCartErrorFn, busEventParent: this.busEvent } } );
            },
            changeTab( { tab, shouldScrollToSelectedHotel = false } ) {
                this.activeTab = tab;

                this.$router.replace( {
                    ...this.$route,
                    query: {
                        ...this.$route.query,
                        tab
                    }
                } );

                setTimeout( () => {
                    const selectedHotelElement = document.querySelector( '.gst-room-card-variant-4.selected' );

                    if ( this.activeTab === EVENT_TABS.PACKAGES &&  selectedHotelElement && shouldScrollToSelectedHotel ) {
                        setTimeout( () => {
                            selectedHotelElement.scrollIntoView( {
                                behavior: 'smooth'
                            } );
                        }, 200 );
                    }
                }, 10 );
            },
            onSelectedHotelReservationDo( value ) {
                this.selectedHotelReservation = value;
            }
        },
        async created( ) {
            this.clearEventFlow( );
        },
        async mounted( ) {
            await this.loadData( );

            if ( this.selectedHotel ) {
                this.activeTab = EVENT_TABS.TICKETS;
            }

            if ( this.isPackageFlow ) {
                this.setHamburgerMenuVisible( false );
            }

            this.busEvent.$on( 'more-info', this.openHotelDetails );
        },
        destroyed( ) {
            ApiCancelService.clear( 'events.one' );
            ApiCancelService.clear( 'packageOffers.oneByEventId' );

            this.busEvent.$off( 'more-info' );

            this.clearEventFlow( );
            this._destroyed = true;
        },
        beforeRouteEnter( to, from, next ) {
            next( ( cmp ) => {
                cmp.previousRoute = from;
            } );
        },
        beforeRouteUpdate( to, from, next ) {
            this.previousRoute = from;
            next( );
        },
    };
</script>

<style lang="scss">
    @import '@scssVariables';
    @import '@scssMixins';

    .gst-event {
        height: 100vh;

        .gst-event__loading {
            padding-top: $header-height + 16px;
        }
    }

    @include mobile-only {
        .gst-event {
            height: 100vh;
            height: calc( var( --vh, 1vh ) * 100 );
        }
    }
</style>
